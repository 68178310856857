import React from "react";
import Header from "../Global/Header";
import Banner from "../Menhomeone/Banner";
import Service from "../Menhomeone/Service";
import Footer from "../Global/Footer";

const Main = () => {
  return (
    <>
      {" "}
      <Header />
      <Banner />
      <div className="page_wrapper">
        <Service />
        <Footer />
      </div>
    </>
  );
};

export default Main;
