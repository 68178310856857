import React from "react";

const Privacy = () => {
  return (
    <section className="row_am service_section about_service">
      <div className="container">
        <div className="row service_blocks">
          <div className="col-md-12">
            <div className="service_text">
              <div className="title_badge">
                <span>Privacy Policy</span>
              </div>
              <h2>Privacy Policy</h2>
              <p>Last updated: February 8, 2025</p>

              <div className="privacy_content">
                <p>
                  This Privacy Policy describes Our policies and procedures on
                  the collection, use and disclosure of Your information when
                  You use the Service and tells You about Your privacy rights
                  and how the law protects You.
                </p>

                <p>
                  We use Your Personal data to provide and improve the Service.
                  By using the Service, You agree to the collection and use of
                  information in accordance with this Privacy Policy.
                </p>

                <h3>Interpretation and Definitions</h3>
                <h4>Interpretation</h4>
                <p>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>

                <h4>Definitions</h4>
                <p>For the purposes of this Privacy Policy:</p>

                <ul>
                  <li>
                    <strong>Account:</strong> A unique account created for You
                    to access our Service or parts of our Service.
                  </li>
                  <li>
                    <strong>Affiliate:</strong> An entity that controls, is
                    controlled by, or is under common control with a party,
                    where "control" means ownership of 50% or more of the
                    shares, equity interest, or other securities entitled to
                    vote for election of directors or other managing authority.
                  </li>
                  <li>
                    <strong>Application:</strong> Refers to the Food Scan app
                    provided by the Company.
                  </li>
                  <li>
                    <strong>Device:</strong> Any device that can access the
                    Service, such as a computer, cellphone, or digital tablet.
                  </li>
                  <li>
                    <strong>Personal Data:</strong> Any information that can
                    identify you or make you identifiable.
                  </li>
                  <li>
                    <strong>Service Provider:</strong> Any third-party company
                    or individual that processes your data on behalf of the
                    Company.
                  </li>
                  <li>
                    <strong>Usage Data:</strong> Data collected automatically
                    during your use of the Service or from the Service
                    infrastructure itself.
                  </li>
                </ul>

                <h3>Collecting and Using Your Personal Data</h3>
                <h4>Types of Data Collected</h4>

                <h5>Personal Data</h5>
                <p>
                  While using our Service, we may ask you to provide certain
                  personally identifiable information that can be used to
                  contact or identify you. This may include, but is not limited
                  to:
                </p>
                <ul>
                  <li>Name</li>
                  <li>Email address</li>
                  <li>Dietary preferences</li>
                  <li>Health information</li>
                </ul>

                <h5>Usage Data</h5>
                <p>
                  Usage Data is collected automatically when using the Service.
                  This may include information such as your device's IP address,
                  browser type, pages visited, time spent on pages, and device
                  identifiers. If you access the Service via a mobile device, we
                  may collect information like your device type, unique ID,
                  operating system, and mobile browser type.
                </p>

                <h3>Use of Your Personal Data</h3>
                <p>We use your personal data for the following purposes:</p>
                <ul>
                  <li>
                    To provide and maintain our Service: To monitor the usage
                    and improve the Service.
                  </li>
                  <li>
                    To manage your Account: To create and manage your user
                    account.
                  </li>
                  <li>
                    To perform a contract: To fulfill our obligations in a
                    contract with you, including the purchase of products or
                    services.
                  </li>
                  <li>
                    To contact you: For updates, service-related communications,
                    or marketing purposes.
                  </li>
                  <li>
                    To provide personalized recommendations: To enhance the app
                    experience by making suggestions based on your data.
                  </li>
                  <li>
                    For analytics: To understand usage trends and improve
                    functionality.
                  </li>
                  <li>
                    For advertising purposes: Using personal data like age and
                    gender to serve targeted ads within the app.
                  </li>
                </ul>

                <h3>Retention of Your Personal Data</h3>
                <p>
                  We will retain your personal data only for as long as
                  necessary for the purposes outlined in this Privacy Policy. If
                  you wish to delete your data, you may request this at any
                  time. We will also retain data for legal and business
                  purposes, as required.
                </p>

                <h3>Transfer of Your Personal Data</h3>
                <p>
                  Your personal data may be processed in locations outside your
                  jurisdiction. By using the Service, you consent to this
                  transfer and processing, ensuring the necessary protections
                  are in place.
                </p>

                <h3>Disclosure of Your Personal Data</h3>
                <p>
                  We do not share your personal data with third parties except
                  in the following situations:
                </p>
                <ul>
                  <li>
                    Business Transactions: If the Company undergoes a merger,
                    acquisition, or asset sale, your personal data may be
                    transferred. We will notify you prior to any such transfer.
                  </li>
                  <li>
                    Legal Requirements: We may disclose personal data if
                    required to do so by law or to protect our rights or the
                    safety of others.
                  </li>
                  <li>
                    Third-Party Integrations: The Service may integrate with
                    third-party services, such as payment processors or social
                    media logins. Please review the privacy policies of these
                    third parties.
                  </li>
                </ul>

                <h3>Security of Your Personal Data</h3>
                <p>
                  We implement secure storage measures to protect your personal
                  data. However, no method of transmission over the Internet or
                  electronic storage is entirely secure, so we cannot guarantee
                  absolute security.
                </p>

                <h3>Children's Privacy</h3>
                <p>
                  Our Service is not directed to individuals under the age of
                  13. We do not knowingly collect personal data from children
                  under 13. If you believe we have, please contact us
                  immediately.
                </p>

                <h3>Your Rights and Control Over Your Data</h3>
                <p>You have the right to:</p>
                <ul>
                  <li>
                    Access your data: Request a copy of your personal data.
                  </li>
                  <li>
                    Amend your data: Update or correct your personal
                    information.
                  </li>
                  <li>
                    Delete your data: Request deletion of your personal data,
                    subject to legal obligations.
                  </li>
                  <li>
                    Opt-out of marketing: You can opt-out of receiving
                    promotional communications at any time.
                  </li>
                </ul>

                <h3>Changes to This Privacy Policy</h3>
                <p>
                  We may update our Privacy Policy periodically. Any changes
                  will be posted here with an updated "Last Updated" date. You
                  will also be notified via email or within the app.
                </p>

                <h3>Contact Us</h3>
                <p>
                  If you have any questions about this Privacy Policy or wish to
                  exercise your data rights, please contact us:
                </p>
                <p>By email: support@foodscan.app</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
