import React from "react";

const Terms = () => {
  return (
    <section className="row_am service_section about_service">
      <div className="container">
        <div className="row service_blocks">
          <div className="col-md-12">
            <div className="service_text">
              <div className="title_badge">
                <span>Terms of Service</span>
              </div>
              <h2>Terms of Service</h2>
              <p>Last updated: February 08, 2025</p>

              <div className="terms_content">
                <p>
                  Please read these terms and conditions carefully before using
                  Our Service.
                </p>

                <h3>Interpretation and Definitions</h3>
                <h4>Interpretation</h4>
                <p>
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>

                <h4>Definitions</h4>
                <p>For the purposes of these Terms and Conditions:</p>

                <ul>
                  <li>
                    <strong>Affiliate</strong> means an entity that controls, is
                    controlled by or is under common control with a party, where
                    "control" means ownership of 50% or more of the shares,
                    equity interest or other securities entitled to vote for
                    election of directors or other managing authority.
                  </li>
                  <li>
                    <strong>Company</strong> (referred to as either "the
                    Company", "We", "Us" or "Our" in this Agreement) refers to
                    PlotPilot Ltd.
                  </li>
                  <li>
                    <strong>Device</strong> means any device that can access the
                    Service such as a computer, a cellphone or a digital tablet.
                  </li>
                  <li>
                    <strong>Service</strong> refers to the Food Scan
                    application.
                  </li>
                  <li>
                    <strong>Terms and Conditions</strong> (also referred as
                    "Terms") mean these Terms and Conditions that form the
                    entire agreement between You and the Company regarding the
                    use of the Service.
                  </li>
                  <li>
                    <strong>Third-party Social Media Service</strong> means any
                    services or content provided by a third-party that may be
                    displayed, included or made available by the Service.
                  </li>
                  <li>
                    <strong>You</strong> means the individual accessing or using
                    the Service, or the company, or other legal entity on behalf
                    of which such individual is accessing or using the Service,
                    as applicable.
                  </li>
                </ul>

                <h3>Acknowledgment</h3>
                <p>
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                </p>
                <p>
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service.
                </p>
                <p>
                  By accessing or using the Service You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions then You may not access the
                  Service.
                </p>
                <p>
                  You represent that you are over the age of 18. The Company
                  does not permit those under 18 to use the Service.
                </p>

                <h3>Links to Other Websites</h3>
                <p>
                  Our Service may contain links to third-party websites or
                  services that are not owned or controlled by the Company.
                </p>
                <p>
                  The Company has no control over, and assumes no responsibility
                  for, the content, privacy policies, or practices of any third
                  party websites or services. You further acknowledge and agree
                  that the Company shall not be responsible or liable, directly
                  or indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with the use of or reliance on any
                  such content, goods or services available on or through any
                  such websites or services.
                </p>

                <h3>Termination</h3>
                <p>
                  We may terminate or suspend Your access immediately, without
                  prior notice or liability, for any reason whatsoever,
                  including without limitation if You breach these Terms and
                  Conditions.
                </p>
                <p>
                  Upon termination, Your right to use the Service will cease
                  immediately.
                </p>

                <h3>Limitation of Liability</h3>
                <p>
                  To the maximum extent permitted by applicable law, in no event
                  shall the Company or its suppliers be liable for any special,
                  incidental, indirect, or consequential damages whatsoever
                  (including, but not limited to, damages for loss of profits,
                  loss of data or other information, for business interruption,
                  for personal injury, loss of privacy arising out of or in any
                  way related to the use of or inability to use the Service,
                  third-party software and/or third-party hardware used with the
                  Service, or otherwise in connection with any provision of
                  these Terms).
                </p>

                <h3>"AS IS" and "AS AVAILABLE" Disclaimer</h3>
                <p>
                  The Service is provided to You "AS IS" and "AS AVAILABLE" and
                  with all faults and defects without warranty of any kind. The
                  Company makes no warranty or undertaking, and makes no
                  representation of any kind that the Service will meet Your
                  requirements, achieve any intended results, be compatible or
                  work with any other software, applications, systems or
                  services, operate without interruption, meet any performance
                  or reliability standards or be error free or that any errors
                  or defects can or will be corrected.
                </p>

                <h3>Governing Law</h3>
                <p>
                  The laws of the Country, excluding its conflicts of law rules,
                  shall govern these Terms and Your use of the Service. Your use
                  of the Application may also be subject to other local, state,
                  national, or international laws.
                </p>

                <h3>Changes to These Terms and Conditions</h3>
                <p>
                  We reserve the right, at Our sole discretion, to modify or
                  replace these Terms at any time. If a revision is material We
                  will make reasonable efforts to provide at least 30 days'
                  notice prior to any new terms taking effect. What constitutes
                  a material change will be determined at Our sole discretion.
                </p>
                <p>
                  By continuing to access or use Our Service after those
                  revisions become effective, You agree to be bound by the
                  revised terms. If You do not agree to the new terms, in whole
                  or in part, please stop using the Service.
                </p>

                <h3>Contact Us</h3>
                <p>
                  If you have any questions about these Terms and Conditions,
                  You can contact us:
                </p>
                <p>By email: support@foodscan.app</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Terms;
