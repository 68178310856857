import React from "react";
import hero from "../../assets/images/hero_image.png";
import appstorebtn from "../../assets/images/appstorebtn.webp";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <section className="banner_section" id="home_sec">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="banner_text">
                <h1>
                  {" "}
                  Snap a picture <span> track your calories </span>{" "}
                </h1>

                <p>
                  Introducing Food Scan, your intelligent nutrition companion.
                  Simply take a picture of your meal or scan a product's barcode
                  to instantly receive detailed calorie and nutrient
                  information.
                </p>
              </div>

              <ul className="app_btn">
                <li>
                  <Link to="https://apps.apple.com/us/app/food-scan-ai-calorie-tracker/id6740452900">
                    <img className="blue_img" src={appstorebtn} alt="image" />
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="hero_img">
                <img src={hero} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Banner;
