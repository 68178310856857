import React from "react";
import for_restaurant from "../../assets/images/for_restaurant.webp";
import for_customer from "../../assets/images/for_customer.webp";

const Service = () => {
  return (
    <>
      <section className="row_am service_section" id="feature_sec">
        <div className="container">
          <div className="section_title" data-aos-duration={1500}>
            <span className="title_badge">Advanced Features</span>
            <h2>Smart AI-Powered Nutrition Tracking</h2>
            <p>
              Experience the future of nutrition tracking with our advanced AI
              technology that makes maintaining a healthy lifestyle effortless.
            </p>
          </div>
          <div className="row service_blocks flex-row-reverse">
            <div className="col-md-6">
              <div className="service_text right_side" data-aos-duration={1500}>
                <h3>How Food Scan Works</h3>
                <p>
                  When you snap a photo with Food Scan, advanced AI gets to
                  work: measuring your food's volume, identifying each
                  ingredient, and calculating detailed nutrition facts - all in
                  seconds.
                </p>
                <ul className="design_block">
                  <h6>AI-Powered Health Insights</h6>
                  <p>
                    Food Scan reviews your health information to provide
                    personalized recommendations, offering real-time tracking of
                    your sleep, meals, and exercise.
                  </p>
                  <h6>Personalized Recommendations</h6>
                  <p>
                    Food Scan personalizes your wellness journey by analyzing
                    your health profile to deliver targeted nutrition and
                    fitness guidance.
                  </p>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="inner_block dark_bg rotate_right"
                data-aos-duration={1500}
              >
                <div className="img">
                  <img src={for_restaurant} alt="image" />
                </div>
              </div>
            </div>
          </div>
          <div className="row service_blocks no_bottom_padding">
            <div className="col-md-6">
              <div className="service_text" data-aos-duration={1500}>
                <span className="title_badge">Accuracy & Features</span>
                <h3>How Accurate is Food Scan?</h3>
                <p>
                  Food Scan achieves 90% accuracy in most scenarios through our
                  advanced AI technology. For complex dishes or hidden
                  ingredients, we offer multiple scanning options to ensure
                  accuracy.
                </p>
                <ul className="design_block">
                  <h6>Multiple Scanning Options</h6>
                  <p>
                    Use our image recognition, barcode scanner, or manual input
                    for different types of foods. For items like smoothies or
                    soups, our describe feature ensures precise tracking.
                  </p>
                  <h6>Reliable Reports</h6>
                  <p>
                    Food Scan draws on an extensive nutritional database to
                    provide accurate assessments. Our system continuously
                    refines its accuracy through regular database updates and
                    detailed food information entries.
                  </p>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner_block" data-aos-duration={1500}>
                <div className="img">
                  <img src={for_customer} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
