import React from "react";
import logo from "../../assets/images/logo.webp";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <header className={"fixed"}>
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </nav>
      </div>
    </header>
  );
};
export default Header;
