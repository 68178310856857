import React, { useEffect } from "react";
import Aos from "aos";
import { Routes, Route } from "react-router-dom";
import Homeone from "../components/Homeone/Main";
import Privacy from "../components/Privacy/Privacy";
import Terms from "../components/Tos/Terms";

const Index = () => {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Homeone />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/tos" element={<Terms />} />
    </Routes>
  );
};

export default Index;
