import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="bottom_footer">
        <div className="container">
          <div className="flex justify-between items-center">
            <p>Made with love ❤️</p>

            <Link to="/privacy">
              <p>Privacy Policy</p>
            </Link>

            <Link to="/tos">
              <p>Terms and Conditions</p>
            </Link>

            <a href="mailto:support@foodscan.app">
              <p>Contact Support</p>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
